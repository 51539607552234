// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-anlagen-js": () => import("./../../../src/pages/anlagen.js" /* webpackChunkName: "component---src-pages-anlagen-js" */),
  "component---src-pages-anlagendaten-js": () => import("./../../../src/pages/anlagendaten.js" /* webpackChunkName: "component---src-pages-anlagendaten-js" */),
  "component---src-pages-artikel-js": () => import("./../../../src/pages/artikel.js" /* webpackChunkName: "component---src-pages-artikel-js" */),
  "component---src-pages-artikeldaten-js": () => import("./../../../src/pages/artikeldaten.js" /* webpackChunkName: "component---src-pages-artikeldaten-js" */),
  "component---src-pages-avp-js": () => import("./../../../src/pages/avp.js" /* webpackChunkName: "component---src-pages-avp-js" */),
  "component---src-pages-benutzer-js": () => import("./../../../src/pages/benutzer.js" /* webpackChunkName: "component---src-pages-benutzer-js" */),
  "component---src-pages-benutzerdaten-js": () => import("./../../../src/pages/benutzerdaten.js" /* webpackChunkName: "component---src-pages-benutzerdaten-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kunden-js": () => import("./../../../src/pages/kunden.js" /* webpackChunkName: "component---src-pages-kunden-js" */),
  "component---src-pages-kundendaten-js": () => import("./../../../src/pages/kundendaten.js" /* webpackChunkName: "component---src-pages-kundendaten-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-profil-js": () => import("./../../../src/pages/profil.js" /* webpackChunkName: "component---src-pages-profil-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-wochenplanung-js": () => import("./../../../src/pages/wochenplanung.js" /* webpackChunkName: "component---src-pages-wochenplanung-js" */)
}

