import styles from './Notification.scss';
import { useNotification } from './Context';
import React from 'react';

import Alert from '@wienenergiegithub/ui-next/src/common/components/Alert/Alert';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const NotificationCenter = () => {
  const { items, remove } = useNotification();
  return (
    <div className={styles.container}>
      <TransitionGroup className={styles.wrapper}>
        {items.map(itm => (
          <CSSTransition
            key={itm.id}
            timeout={700}
            classNames={{
              enterActive: styles.transitionEnterActive,
              enterDone: styles.transitionEnter,
              exitDone: styles.transitionExit,
              exitActive: styles.transitionExitActive,
            }}
          >
            <Alert
              {...itm}
              handleClose={() => remove(itm.id)}
              fontAwesomeIcon={itm.icon}
              hasCloseButton
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>
  );
};

export default NotificationCenter;
