module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Wien Energie Müllanlieferung","short_name":"Müllanlieferung","start_url":"/","background_color":"#eb6626","theme_color":"#eb6626","display":"minimal-ui","icon":"src/assets/icons/logo-full.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"962e665ac11605e8416cbae5aff3cfe4"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
