import React, { createContext, useReducer } from 'react';

const NotificationContext = createContext();

const notificationReducer = (state, action) => {
  switch (action.type) {
    case 'add': {
      return { items: [...state.items, action.payload] };
    }
    case 'remove': {
      return {
        items: state.items.filter(item => item.id !== action.payload.id),
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export const NotificationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(notificationReducer, { items: [] });
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch };
  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};
export const useNotification = () => {
  const context = React.useContext(NotificationContext);
  if (context === undefined) {
    throw new Error(
      'useNotification must be used within a NotificationProvider',
    );
  }
  const add = payload => context.dispatch({ type: 'add', payload });
  const remove = id => context.dispatch({ type: 'remove', payload: { id } });
  return { items: context.state.items, add, remove };
};

export default NotificationContext;
