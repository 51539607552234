import React from 'react';
import { NotificationProvider } from '@wienenergiegithub/layout/src/common/modules/Notification/Context';
import NotificationCenter from '@wienenergiegithub/layout/src/common/modules/Notification/Notification';

export const wrapRootElement = ({ element }) => (
  <NotificationProvider>
    <NotificationCenter />
    {element}
  </NotificationProvider>
);
